@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

table {
  tr {
    td {
      position: relative;
    }
  }
}

.ant-modal-wrap, .ant-modal-mask {
  z-index: 5000 !important;
}

.ant-table-tbody {
  & > tr > td {
    position: relative;
  }

  .ant-table-row {
    &.row-product {
      td {
        background-color: #f2f2f2 !important;
        font-weight: 600;
      }
    }
  }

}

@media only screen and (max-width: 768px) {
  .ant-table-thead {
    display: none;
  }

  .ant-table-row {
    & > * {
      display: block;
      width: 100%;
      &:first-child {
        border-top: 3px solid #999;
      }
    }
    td:empty {
      display: none;
    }
    td.bounce-column {
      min-height: 90px;
    }
    td.line-item-sku-cell,
    td.line-item-qty-cell,
    td.line-item-price-cell,
    td.line-item-actions-cell {
      display: inline-block;
      width: 50%;
    }

    td.line-item-price-cell,
    td.line-item-actions-cell {
      text-align: right;
    }


    td.line-item-qty-cell,
    td.line-item-actions-cell {
      height: 65px;
    }
  }

  .ant-col-8,
  .ant-col-12,
  .ant-col-24 {
    width: 100%;
  }
}

.ant-table-expanded-row {
  @media only screen and (max-width: 768px) {
    td {
      display: block;
    }
  }
}

.ant-table-row {
  .stock-column {
    text-align: center;
    position: relative;
    min-height: 50px;
    padding: 0;
  }
}

.warehouse-stock-product-row {
  & > .ant-table-row-expand-icon-cell {
    & > * {
      display: none;
    }
  }

  & > td {
    border-top: 10px solid #f0f2f5 !important;

    @media only screen and (max-width: 768px) {
      border-top: none !important;

      &:nth-child(2) {
        border-top: 10px solid #f0f2f5 !important;
      }
    }

    &.stock-column {
      @media only screen and (max-width: 768px) {
        display: inline-block;
        width: 33.33%;
      }
    }
  }

  & + .ant-table-expanded-row {
    .ant-table-tbody {
      tr {
        & > td {
          border-top: none !important;

          @media only screen and (max-width: 768px) {
            border-bottom: none !important;
          }

          &.stock-column {

            @media only screen and (max-width: 768px) {
              display: inline-block;
              width: 33.33%;
              border-top: 1px solid #f2f2f2 !important;
            }
          }

          &.stock-actions {
            @media only screen and (max-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              border-top: 1px solid #f2f2f2 !important;
              border-bottom: 3px solid #f2f2f2 !important;
              & > * {
                margin: 0 10px;
              }
            }
          }
        }
      }
    }
  }
}